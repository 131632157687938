<template>
  <div class="product-filter box1">
    <div action="#" class="form mb-0">
      <h4>Filter By</h4>
      <div class="filter-options price-filter">
        <h6>Price</h6>
        <input type="range" class="form-control-range mb-3" v-model="price" :min="minPrice" :max="maxPrice"
          @change="priceChanged" data-toggle="tooltip" data-placement="top" :title="price" />
        <ul class="list">
          <li><input type="text" class="form-control" v-model="minPrice" readonly></li>
          <li><span>to</span></li>
          <li><input type="text" class="form-control" v-model="price" readonly></li>
        </ul>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#feature-filter" role="button" aria-expanded="true"
          aria-controls="feature-filter">
          Key Feature
        </h6>
        <div class="wrapper collapse show" id="feature-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="perfectFry" id="feature-0" @change="perfectFryChanged" />
                <label for="feature-0">PerfectFry</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="combiInduction" id="feature-1"
                  @change="combiInductionemotionLightChanged" />
                <label for="feature-1">Combi induction</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="directSelect" id="feature-2" @change="directSelectChanged" />
                <label for="feature-2">DirectSelect</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="directSelectClassic" id="feature-3"
                  @change="directSelectClassicChanged" />
                <label for="feature-3">directselectclassic</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="directSelectPremium" id="feature-4"
                  @change="directSelectPremiumChanged" />
                <label for="feature-4">DirectSelect Premium</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="extendedFlexInduction" id="feature-5"
                  @change="extendedFlexInductionChanged" />
                <label for="feature-5">Extended flexinduction</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="flameSelect" id="feature-6" @change="flameSelectChanged" />
                <label for="feature-6">Flame select / 7 segment display</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="flexInduction" id="feature-7" @change="flexInductionChanged" />
                <label for="feature-7">Flexinduction</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="fryingSensorPlus" id="feature-8" @change="fryingSensorPlusChanged" />
                <label for="feature-8">fryingSensor Plus</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input v-model="moveMode" type="checkbox" id="feature-10" @change="moveModeChanged" />
                <label for="feature-10">MoveMode</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="perfectCook" id="feature-11" @change="perfectCookChanged" />
                <label for="feature-11">PerfectCook ready</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input v-model="powerBoost" id="feature-12" type="checkbox" @change="powerBoostChanged" />
                <label for="feature-12">PowerBoost</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="touchControl" id="feature-13" @change="touchControlChanged" />
                <label for="feature-13">TouchControl</label>
              </div>
            </li>

            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="wokBurner" id="feature-14" @change="wokBurnerChanged" />
                <label for="feature-14">Wok burner</label>
              </div>
            </li>

          </ul>
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#width-filter" role="button" aria-expanded="true"
          aria-controls="width-filter">
          Width (cm)
        </h6>
        <div class="wrapper collapse show" id="width-filter">
          <VueMultiselect v-model="width" :options="widths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select widths" :searchable="false" selectLabel="" deselectLabel=""
            @input="widthChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#series-filter" role="button" aria-expanded="true"
          aria-controls="series-filter">
          Product series
        </h6>
        <div class="wrapper collapse show" id="series-filter">
          <VueMultiselect v-model="series" :options="serieses" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select serieses" :searchable="false" selectLabel="" deselectLabel=""
            @input="seriesChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#appliance-filter" role="button" aria-expanded="true"
          aria-controls="appliance-filter">
          Smart Appliance
        </h6>
        <div class="wrapper collapse show" id="appliance-filter">
          <ul class="list">
            <li>
              <div class="checkbox">
                <input type="checkbox" v-model="remoteDiagnostics" id="appliance-1"
                  @change="remoteDiagnosticsChanged" />
                <label for="appliance-1">Remote Diagnostics</label>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#colour-filter" role="button" aria-expanded="true"
          aria-controls="colour-filter">
          Main Colour of Product
        </h6>
        <div class="wrapper collapse show" id="colour-filter">
          <VueMultiselect v-model="color" :options="colors" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select colours" :searchable="false" selectLabel="" deselectLabel=""
            @input="colorChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#burners-filter" role="button" aria-expanded="true"
          aria-controls="burners-filter">
          Number of gas burners
        </h6>
        <div class="wrapper collapse show" id="burners-filter">
          <VueMultiselect v-model="burner" :options="burners" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select burners" :searchable="false" selectLabel="" deselectLabel=""
            @input="burnerChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#heating-type-filter" role="button" aria-expanded="true"
          aria-controls="heating-type-filter">
          Heating type
        </h6>
        <div class="wrapper collapse show" id="heating-type-filter">
          <VueMultiselect v-model="heatingType" :options="heatingTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select heating types" :searchable="false" selectLabel=""
            deselectLabel="" @input="heatingTypeChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#induction-plate-filter" role="button"
          aria-expanded="true" aria-controls="induction-plate-filter">
          Number of induction plates
        </h6>
        <div class="wrapper collapse show" id="induction-plate-filter">
          <VueMultiselect v-model="inductionPlate" :options="inductionPlates" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select induction plates" :searchable="false" selectLabel=""
            deselectLabel="" @input="inductionPlateChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#cooker-control-filter" role="button"
          aria-expanded="true" aria-controls="cooker-control-filter">
          Self-sufficient cooker control
        </h6>
        <div class="wrapper collapse show" id="cooker-control-filter">
          <VueMultiselect v-model="selfSufficient" :options="selfSufficients" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select self sufficiencies" :searchable="false" selectLabel=""
            deselectLabel="" @input="selfSufficientChanged" />
        </div>
      </div>


      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#height-filter" role="button" aria-expanded="true"
          aria-controls="height-filter">
          Height
        </h6>
        <div class="wrapper collapse show" id="height-filter">
          <VueMultiselect v-model="height" :options="heights" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select heights" :searchable="false" selectLabel="" deselectLabel=""
            @input="heightChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#depth-filter" role="button" aria-expanded="true"
          aria-controls="depth-filter">
          Depth
        </h6>
        <div class="wrapper collapse show" id="depth-filter">
          <VueMultiselect v-model="depth" :options="depths" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select depths" :searchable="false" selectLabel="" deselectLabel=""
            @input="depthChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#installation-filter" role="button" aria-expanded="true"
          aria-controls="installation-filter">
          Installation Type
        </h6>
        <div class="wrapper collapse show" id="installation-filter">
          <VueMultiselect v-model="installation" :options="installations" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select installation types" :searchable="false" selectLabel=""
            deselectLabel="" @input="installationChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#control-filter" role="button" aria-expanded="true"
          aria-controls="type-filter">
          Type of control
        </h6>
        <div class="wrapper collapse show" id="control-filter">
          <VueMultiselect v-model="controlType" :options="controlTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select control types" :searchable="false" selectLabel=""
            deselectLabel="" @input="controlTypeChanged" />
        </div>
      </div>

      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#source-filter" role="button" aria-expanded="true"
          aria-controls="source-filter">
          Energy Sources
        </h6>
        <div class="wrapper collapse show" id="source-filter">
          <VueMultiselect v-model="energySource" :options="energySources" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select energy sources" :searchable="false" selectLabel=""
            deselectLabel="" @input="energySourceChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#plug-type-filter" role="button" aria-expanded="true"
          aria-controls="plug-type-filter">
          Plug type
        </h6>
        <div class="wrapper collapse show" id="plug-type-filter">
          <VueMultiselect v-model="plugType" :options="plugTypes" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select plug types" :searchable="false" selectLabel="" deselectLabel=""
            @input="plugTypeChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#material-filter" role="button" aria-expanded="true"
          aria-controls="material-filter">
          Basic surface material
        </h6>
        <div class="wrapper collapse show" id="material-filter">
          <VueMultiselect v-model="surfaceMaterial" :options="surfaceMaterials" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select basic surface materials" :searchable="false" selectLabel=""
            deselectLabel="" @input="surfaceMaterialChanged" />
        </div>
      </div>
      <div class="filter-options dropdown">
        <h6 class="heading" data-toggle="collapse" data-target="#number-of-burner-filter" role="button"
          aria-expanded="true" aria-controls="number-of-burner-filter">
          Total number of cooktop burners
        </h6>
        <div class="wrapper collapse show" id="number-of-burner-filter">
          <VueMultiselect v-model="numberOfBurner" :options="numberOfBurners" :multiple="true" :close-on-select="true"
            open-direction="bottom" placeholder="Select number of burners" :searchable="false" selectLabel=""
            deselectLabel="" @input="numberOfBurnerChanged" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import _ from "lodash";
import ProductService from "@/services/product.service";
import VueMultiselect from "vue-multiselect";

export default {
  name: "HobsFilters",
  data: function () {
    return {
      widths: [],
      colors: [],
      burners: [],
      heatingTypes: [],
      inductionPlates: [],
      selfSufficients: [],
      serieses: [],
      heights: [],
      depths: [],
      installations: [],
      controlTypes: [],
      energySources: [],
      plugTypes: [],
      numberOfBurners: [],
      surfaceMaterials: [],
      minPrice: 0,
      maxPrice: 0,
      price: 0,

      width: [],
      color: [],
      burner: [],
      heatingType: [],
      inductionPlate: [],
      selfSufficient: [],
      series: [],
      height: [],
      depth: [],
      installation: [],
      controlType: [],
      energySource: [],
      plugType: [],
      numberOfBurner: [],
      surfaceMaterial: [],
      perfectFry: false,
      combiInduction: false,
      directSelect: false,
      directSelectClassic: false,
      directSelectPremium: false,
      extendedFlexInduction: false,
      flameSelect: false,
      flexInduction: false,
      fryingSensorPlus: false,
      moveMode: false,
      perfectCook: false,
      powerBoost: false,
      touchControl: false,
      wokBurner: false,
      remoteDiagnostics: false,
    };
  },
  components: {
    VueMultiselect,
  },
  watch: {
    // call again the method if the route changes
    selectedBrands: function () {
      this.bindValues();
    },
  },
  mounted() {
    this.bindValues();
    if (this.selectedFilters.category == this.selectedSubCategory.id) {
      this.existingFilters();
    }
  },
  computed: {
    selectedSubCategory() {
      return this.$store.state.subCategory.selectedSubCategory;
    },
    selectedBrands() {
      return this.$store.state.brand.selectedBrands;
    },
    selectedFilters() {
      return this.$store.state.filters.selectedFilters;
    }
  },
  methods: {
    bindValues() {
      ProductService.minMaxPriceFor(
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.minPrice = response.price__min;
        this.maxPrice = response.price__max;
        this.price = response.price__max;
        if (this.selectedFilters.price__lte) {
          this.price = Number(this.selectedFilters.price__lte);
        }
      });
      ProductService.valuesFor(
        "attr_width",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.widths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_col_main",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.colors = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_gas_burners_number",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.burners = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_type_heating",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.heatingTypes = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_induction_plates_number",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.inductionPlates = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_self_sufficiency",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.selfSufficients = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "series",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.serieses = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_height",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.heights = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_depth",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.depths = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_inst_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.installations = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_control_setting",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.controlTypes = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_energy_input",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.energySources = response.filter(
          (item) => !["", null].includes(item)
        );
      });
      ProductService.valuesFor(
        "attr_plug_type",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.plugTypes = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_positions",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.numberOfBurners = response.filter((item) => !["", null].includes(item));
      });
      ProductService.valuesFor(
        "attr_surface_basic_mat",
        this.selectedSubCategory.id,
        _.map(this.selectedBrands, "id").toString()
      ).then((response) => {
        this.surfaceMaterials = response.filter((item) => !["", null].includes(item));
      });
    },

    priceChanged() {
      let filters = { price__lte: this.price };
      this.$emit("changeFilters", filters);
    },

    widthChanged() {
      let filters = {
        attr_width__in: _.map(this.width, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    colorChanged() {
      let filters = {
        attr_col_main__in: _.map(this.color, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    burnerChanged() {
      let filters = {
        attr_gas_burners_number__in: _.map(this.burner, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    heatingTypeChanged() {
      let filters = {
        attr_type_heating__in: _.map(this.heatingType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    inductionPlateChanged() {
      let filters = {
        attr_induction_plates_number__in: _.map(this.inductionPlate, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    seriesChanged() {
      let filters = {
        series__in: _.map(this.series, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },
    selfSufficientChanged() {
      let filters = {
        attr_self_sufficiency__in: _.map(this.selfSufficient, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    heightChanged() {
      let filters = {
        attr_height__in: _.map(this.height, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    depthChanged() {
      let filters = {
        attr_depth__in: _.map(this.depth, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    installationChanged() {
      let filters = {
        attr_inst_type__in: _.map(this.installation, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    controlTypeChanged() {
      let filters = {
        attr_control_setting__in: _.map(this.controlType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    energySourceChanged() {
      let filters = {
        attr_energy_input__in: _.map(this.energySource, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    plugTypeChanged() {
      let filters = {
        attr_plug_type__in: _.map(this.plugType, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    numberOfBurnerChanged() {
      let filters = {
        attr_positions__in: _.map(this.numberOfBurner, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    surfaceMaterialChanged() {
      let filters = {
        attr_surface_basic_mat__in: _.map(this.surfaceMaterial, (item) => {
          return '"' + item + '"';
        }).toString(),
      };
      this.$emit("changeFilters", filters);
    },

    perfectFryChanged() {
      let filters = {
        attr_roasting_sensor_bool: this.perfectFry ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    combiInductionemotionLightChanged() {
      let filters = {
        attr_combiinduction: this.combiInduction ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    directSelectChanged() {
      let filters = {
        attr_directselect: this.directSelect ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    directSelectClassicChanged() {
      let filters = {
        attr_directselectclassic: this.directSelectClassic ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    directSelectPremiumChanged() {
      let filters = {
        attr_directselectpremium: this.directSelectPremium ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    extendedFlexInductionChanged() {
      let filters = {
        attr_extendedflexinduction: this.extendedFlexInduction ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    fryingSensorPlusChanged() {
      let filters = {
        attr_fryingsensor: this.fryingSensorPlus ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    flameSelectChanged() {
      let filters = {
        attr_flameselect: this.flameSelect ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    flexInductionChanged() {
      let filters = {
        attr_flexinduction: this.flexInduction ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    moveModeChanged() {
      let filters = {
        attr_movemodeinduction_ih6_2: this.moveMode ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    perfectCookChanged() {
      let filters = {
        attr_perfectcook_ready: this.perfectCook ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    powerBoostChanged() {
      let filters = {
        attr_powerboost: this.powerBoost ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    touchControlChanged() {
      let filters = {
        attr_touchcontrol: this.touchControl ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    wokBurnerChanged() {
      let filters = {
        attr_wokburner: this.wokBurner ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    remoteDiagnosticsChanged() {
      let filters = {
        attr_remote_diagnostics: this.remoteDiagnostics ? "Yes" : "",
      };
      this.$emit("changeFilters", filters);
    },
    existingFilters() {
      this.perfectFry = this.selectedFilters.attr_roasting_sensor_bool;
      this.combiInduction = this.selectedFilters.attr_combiinduction;
      this.directSelect = this.selectedFilters.attr_directselect;
      this.directSelectClassic = this.selectedFilters.attr_directselectclassic;
      this.directSelectPremium = this.selectedFilters.attr_directselectpremium;
      this.extendedFlexInduction = this.selectedFilters.attr_extendedflexinduction;
      this.fryingSensorPlus = this.selectedFilters.attr_fryingsensor;
      this.flameSelect = this.selectedFilters.attr_flameselect;
      this.flexInduction = this.selectedFilters.attr_flexinduction;
      this.moveMode = this.selectedFilters.attr_movemodeinduction_ih6_2;
      this.perfectCook = this.selectedFilters.attr_perfectcook_ready;
      this.powerBoost = this.selectedFilters.attr_powerboost;
      this.touchControl = this.selectedFilters.attr_touchcontrol;
      this.wokBurner = this.selectedFilters.attr_wokburner;
      this.remoteDiagnostics = this.selectedFilters.attr_remote_diagnostics;


      if (this.selectedFilters.attr_width__in) {
        let sortedWidths = [];
        let unsortedWidths = this.selectedFilters.attr_width__in.split('","');
        unsortedWidths.map((width) => {
          width = width.replaceAll('"', '')
          sortedWidths.push(width)
        });
        this.width = sortedWidths;
      }

      if (this.selectedFilters.attr_gas_burners_number__in) {
        let sortedBurners = [];
        let unsortedBurners = this.selectedFilters.attr_gas_burners_number__in.split('","');
        unsortedBurners.map((burner) => {
          burner = burner.replaceAll('"', '')
          sortedBurners.push(burner)
        });
        this.burner = sortedBurners;
      }

      if (this.selectedFilters.attr_col_main__in) {
        let sortedColors = [];
        let unsortedColors = this.selectedFilters.attr_col_main__in.split('","');
        unsortedColors.map((color) => {
          color = color.replaceAll('"', '')
          sortedColors.push(color)
        });
        this.color = sortedColors;
      }

      if (this.selectedFilters.series__in) {
        let sortedSeries = [];
        let unsortedSeries = this.selectedFilters.series__in.split('","');
        unsortedSeries.map((series) => {
          series = series.replaceAll('"', '')
          sortedSeries.push(series)
        });
        this.series = sortedSeries;
      }

      if (this.selectedFilters.attr_type_heating__in) {
        let sortedHeatingTypes = [];
        let unsortedHeatingTypes = this.selectedFilters.attr_type_heating__in.split('","');
        unsortedHeatingTypes.map((heatingType) => {
          heatingType = heatingType.replaceAll('"', '')
          sortedHeatingTypes.push(heatingType)
        });
        this.heatingType = sortedHeatingTypes;
      }

      if (this.selectedFilters.attr_induction_plates_number__in) {
        let sortedInductionPlates = [];
        let unsortedInductionPlates = this.selectedFilters.attr_induction_plates_number__in.split('","');
        unsortedInductionPlates.map((plate) => {
          plate = plate.replaceAll('"', '')
          sortedInductionPlates.push(plate)
        });
        this.inductionPlate = sortedInductionPlates;
      }

      if (this.selectedFilters.attr_self_sufficiency__in) {
        let sortedSufficiencies = [];
        let unsortedSufficiencies = this.selectedFilters.attr_self_sufficiency__in.split('","');
        unsortedSufficiencies.map((sufficiency) => {
          sufficiency = sufficiency.replaceAll('"', '')
          sortedSufficiencies.push(sufficiency)
        });
        this.selfSufficient = sortedSufficiencies;
      }

      if (this.selectedFilters.attr_height__in) {
        let sortedHeights = [];
        let unsortedHeights = this.selectedFilters.attr_height__in.split('","');
        unsortedHeights.map((height) => {
          height = height.replaceAll('"', "");
          sortedHeights.push(height);
        });
        this.height = sortedHeights;
      }

      if (this.selectedFilters.attr_depth__in) {
        let sortedDepths = [];
        let unsortedDepths = this.selectedFilters.attr_depth__in.split('","');
        unsortedDepths.map((depth) => {
          depth = depth.replaceAll('"', "");
          sortedDepths.push(depth);
        });
        this.depth = sortedDepths;
      }

      if (this.selectedFilters.attr_inst_type__in) {
        let sortedInstallations = [];
        let unsortedInstallations = this.selectedFilters.attr_inst_type__in.split(
          '","'
        );
        unsortedInstallations.map((installation) => {
          installation = installation.replaceAll('"', "");
          sortedInstallations.push(installation);
        });
        this.installation = sortedInstallations;
      }

      if (this.selectedFilters.attr_control_setting__in) {
        let sortedControls = [];
        let unsortedControls = this.selectedFilters.attr_control_setting__in.split(
          '","'
        );
        unsortedControls.map((control) => {
          control = control.replaceAll('"', "");
          sortedControls.push(control);
        });
        this.controlType = sortedControls;
      }

      if (this.selectedFilters.attr_energy_input__in) {
        let sortedSources = [];
        let unsortedSources = this.selectedFilters.attr_energy_input__in.split(
          '","'
        );
        unsortedSources.map((source) => {
          source = source.replaceAll('"', "");
          sortedSources.push(source);
        });
        this.energySource = sortedSources;
      }

      if (this.selectedFilters.attr_plug_type__in) {
        let sortedPlugs = [];
        let unsortedPlugs = this.selectedFilters.attr_plug_type__in.split(
          '","'
        );
        unsortedPlugs.map((plug) => {
          plug = plug.replaceAll('"', "");
          sortedPlugs.push(plug);
        });
        this.plugType = sortedPlugs;
      }

      if (this.selectedFilters.attr_positions__in) {
        let sortedNumbers = [];
        let unsortedNumbers = this.selectedFilters.attr_positions__in.split(
          '","'
        );
        unsortedNumbers.map((number) => {
          number = number.replaceAll('"', "");
          sortedNumbers.push(number);
        });
        this.numberOfBurner = sortedNumbers;
      }

      if (this.selectedFilters.attr_surface_basic_mat__in) {
        let sortedMaterials = [];
        let unsortedMaterials = this.selectedFilters.attr_surface_basic_mat__in.split(
          '","'
        );
        unsortedMaterials.map((material) => {
          material = material.replaceAll('"', "");
          sortedMaterials.push(material);
        });
        this.surfaceMaterial = sortedMaterials;
      }
    }
  },
};
</script>

<style scoped></style>