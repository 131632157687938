var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "product-filter box1" }, [
    _c("div", { staticClass: "form mb-0", attrs: { action: "#" } }, [
      _c("h4", [_vm._v("Filter By")]),
      _c("div", { staticClass: "filter-options price-filter" }, [
        _c("h6", [_vm._v("Price")]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.price,
              expression: "price"
            }
          ],
          staticClass: "form-control-range mb-3",
          attrs: {
            type: "range",
            min: _vm.minPrice,
            max: _vm.maxPrice,
            "data-toggle": "tooltip",
            "data-placement": "top",
            title: _vm.price
          },
          domProps: { value: _vm.price },
          on: {
            change: _vm.priceChanged,
            __r: function($event) {
              _vm.price = $event.target.value
            }
          }
        }),
        _c("ul", { staticClass: "list" }, [
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.minPrice,
                  expression: "minPrice"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.minPrice },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.minPrice = $event.target.value
                }
              }
            })
          ]),
          _vm._m(0),
          _c("li", [
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.price,
                  expression: "price"
                }
              ],
              staticClass: "form-control",
              attrs: { type: "text", readonly: "" },
              domProps: { value: _vm.price },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.price = $event.target.value
                }
              }
            })
          ])
        ])
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#feature-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "feature-filter"
            }
          },
          [_vm._v(" Key Feature ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "feature-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.perfectFry,
                        expression: "perfectFry"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-0" },
                    domProps: {
                      checked: Array.isArray(_vm.perfectFry)
                        ? _vm._i(_vm.perfectFry, null) > -1
                        : _vm.perfectFry
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.perfectFry,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.perfectFry = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.perfectFry = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.perfectFry = $$c
                          }
                        },
                        _vm.perfectFryChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-0" } }, [
                    _vm._v("PerfectFry")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.combiInduction,
                        expression: "combiInduction"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-1" },
                    domProps: {
                      checked: Array.isArray(_vm.combiInduction)
                        ? _vm._i(_vm.combiInduction, null) > -1
                        : _vm.combiInduction
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.combiInduction,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.combiInduction = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.combiInduction = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.combiInduction = $$c
                          }
                        },
                        _vm.combiInductionemotionLightChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-1" } }, [
                    _vm._v("Combi induction")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.directSelect,
                        expression: "directSelect"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-2" },
                    domProps: {
                      checked: Array.isArray(_vm.directSelect)
                        ? _vm._i(_vm.directSelect, null) > -1
                        : _vm.directSelect
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.directSelect,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.directSelect = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.directSelect = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.directSelect = $$c
                          }
                        },
                        _vm.directSelectChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-2" } }, [
                    _vm._v("DirectSelect")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.directSelectClassic,
                        expression: "directSelectClassic"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-3" },
                    domProps: {
                      checked: Array.isArray(_vm.directSelectClassic)
                        ? _vm._i(_vm.directSelectClassic, null) > -1
                        : _vm.directSelectClassic
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.directSelectClassic,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.directSelectClassic = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.directSelectClassic = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.directSelectClassic = $$c
                          }
                        },
                        _vm.directSelectClassicChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-3" } }, [
                    _vm._v("directselectclassic")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.directSelectPremium,
                        expression: "directSelectPremium"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-4" },
                    domProps: {
                      checked: Array.isArray(_vm.directSelectPremium)
                        ? _vm._i(_vm.directSelectPremium, null) > -1
                        : _vm.directSelectPremium
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.directSelectPremium,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.directSelectPremium = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.directSelectPremium = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.directSelectPremium = $$c
                          }
                        },
                        _vm.directSelectPremiumChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-4" } }, [
                    _vm._v("DirectSelect Premium")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.extendedFlexInduction,
                        expression: "extendedFlexInduction"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-5" },
                    domProps: {
                      checked: Array.isArray(_vm.extendedFlexInduction)
                        ? _vm._i(_vm.extendedFlexInduction, null) > -1
                        : _vm.extendedFlexInduction
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.extendedFlexInduction,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.extendedFlexInduction = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.extendedFlexInduction = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.extendedFlexInduction = $$c
                          }
                        },
                        _vm.extendedFlexInductionChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-5" } }, [
                    _vm._v("Extended flexinduction")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.flameSelect,
                        expression: "flameSelect"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-6" },
                    domProps: {
                      checked: Array.isArray(_vm.flameSelect)
                        ? _vm._i(_vm.flameSelect, null) > -1
                        : _vm.flameSelect
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.flameSelect,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.flameSelect = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.flameSelect = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.flameSelect = $$c
                          }
                        },
                        _vm.flameSelectChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-6" } }, [
                    _vm._v("Flame select / 7 segment display")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.flexInduction,
                        expression: "flexInduction"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-7" },
                    domProps: {
                      checked: Array.isArray(_vm.flexInduction)
                        ? _vm._i(_vm.flexInduction, null) > -1
                        : _vm.flexInduction
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.flexInduction,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.flexInduction = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.flexInduction = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.flexInduction = $$c
                          }
                        },
                        _vm.flexInductionChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-7" } }, [
                    _vm._v("Flexinduction")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.fryingSensorPlus,
                        expression: "fryingSensorPlus"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-8" },
                    domProps: {
                      checked: Array.isArray(_vm.fryingSensorPlus)
                        ? _vm._i(_vm.fryingSensorPlus, null) > -1
                        : _vm.fryingSensorPlus
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.fryingSensorPlus,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.fryingSensorPlus = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.fryingSensorPlus = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.fryingSensorPlus = $$c
                          }
                        },
                        _vm.fryingSensorPlusChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-8" } }, [
                    _vm._v("fryingSensor Plus")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.moveMode,
                        expression: "moveMode"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-10" },
                    domProps: {
                      checked: Array.isArray(_vm.moveMode)
                        ? _vm._i(_vm.moveMode, null) > -1
                        : _vm.moveMode
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.moveMode,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.moveMode = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.moveMode = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.moveMode = $$c
                          }
                        },
                        _vm.moveModeChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-10" } }, [
                    _vm._v("MoveMode")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.perfectCook,
                        expression: "perfectCook"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-11" },
                    domProps: {
                      checked: Array.isArray(_vm.perfectCook)
                        ? _vm._i(_vm.perfectCook, null) > -1
                        : _vm.perfectCook
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.perfectCook,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.perfectCook = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.perfectCook = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.perfectCook = $$c
                          }
                        },
                        _vm.perfectCookChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-11" } }, [
                    _vm._v("PerfectCook ready")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.powerBoost,
                        expression: "powerBoost"
                      }
                    ],
                    attrs: { id: "feature-12", type: "checkbox" },
                    domProps: {
                      checked: Array.isArray(_vm.powerBoost)
                        ? _vm._i(_vm.powerBoost, null) > -1
                        : _vm.powerBoost
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.powerBoost,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.powerBoost = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.powerBoost = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.powerBoost = $$c
                          }
                        },
                        _vm.powerBoostChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-12" } }, [
                    _vm._v("PowerBoost")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.touchControl,
                        expression: "touchControl"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-13" },
                    domProps: {
                      checked: Array.isArray(_vm.touchControl)
                        ? _vm._i(_vm.touchControl, null) > -1
                        : _vm.touchControl
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.touchControl,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.touchControl = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.touchControl = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.touchControl = $$c
                          }
                        },
                        _vm.touchControlChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-13" } }, [
                    _vm._v("TouchControl")
                  ])
                ])
              ]),
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.wokBurner,
                        expression: "wokBurner"
                      }
                    ],
                    attrs: { type: "checkbox", id: "feature-14" },
                    domProps: {
                      checked: Array.isArray(_vm.wokBurner)
                        ? _vm._i(_vm.wokBurner, null) > -1
                        : _vm.wokBurner
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.wokBurner,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 && (_vm.wokBurner = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.wokBurner = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.wokBurner = $$c
                          }
                        },
                        _vm.wokBurnerChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "feature-14" } }, [
                    _vm._v("Wok burner")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#width-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "width-filter"
            }
          },
          [_vm._v(" Width (cm) ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "width-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.widths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select widths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.widthChanged },
              model: {
                value: _vm.width,
                callback: function($$v) {
                  _vm.width = $$v
                },
                expression: "width"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#series-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "series-filter"
            }
          },
          [_vm._v(" Product series ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "series-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.serieses,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select serieses",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.seriesChanged },
              model: {
                value: _vm.series,
                callback: function($$v) {
                  _vm.series = $$v
                },
                expression: "series"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#appliance-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "appliance-filter"
            }
          },
          [_vm._v(" Smart Appliance ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "appliance-filter" }
          },
          [
            _c("ul", { staticClass: "list" }, [
              _c("li", [
                _c("div", { staticClass: "checkbox" }, [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.remoteDiagnostics,
                        expression: "remoteDiagnostics"
                      }
                    ],
                    attrs: { type: "checkbox", id: "appliance-1" },
                    domProps: {
                      checked: Array.isArray(_vm.remoteDiagnostics)
                        ? _vm._i(_vm.remoteDiagnostics, null) > -1
                        : _vm.remoteDiagnostics
                    },
                    on: {
                      change: [
                        function($event) {
                          var $$a = _vm.remoteDiagnostics,
                            $$el = $event.target,
                            $$c = $$el.checked ? true : false
                          if (Array.isArray($$a)) {
                            var $$v = null,
                              $$i = _vm._i($$a, $$v)
                            if ($$el.checked) {
                              $$i < 0 &&
                                (_vm.remoteDiagnostics = $$a.concat([$$v]))
                            } else {
                              $$i > -1 &&
                                (_vm.remoteDiagnostics = $$a
                                  .slice(0, $$i)
                                  .concat($$a.slice($$i + 1)))
                            }
                          } else {
                            _vm.remoteDiagnostics = $$c
                          }
                        },
                        _vm.remoteDiagnosticsChanged
                      ]
                    }
                  }),
                  _c("label", { attrs: { for: "appliance-1" } }, [
                    _vm._v("Remote Diagnostics")
                  ])
                ])
              ])
            ])
          ]
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#colour-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "colour-filter"
            }
          },
          [_vm._v(" Main Colour of Product ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "colour-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.colors,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select colours",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.colorChanged },
              model: {
                value: _vm.color,
                callback: function($$v) {
                  _vm.color = $$v
                },
                expression: "color"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#burners-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "burners-filter"
            }
          },
          [_vm._v(" Number of gas burners ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "burners-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.burners,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select burners",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.burnerChanged },
              model: {
                value: _vm.burner,
                callback: function($$v) {
                  _vm.burner = $$v
                },
                expression: "burner"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#heating-type-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "heating-type-filter"
            }
          },
          [_vm._v(" Heating type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "heating-type-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.heatingTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select heating types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.heatingTypeChanged },
              model: {
                value: _vm.heatingType,
                callback: function($$v) {
                  _vm.heatingType = $$v
                },
                expression: "heatingType"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#induction-plate-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "induction-plate-filter"
            }
          },
          [_vm._v(" Number of induction plates ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "induction-plate-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.inductionPlates,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select induction plates",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.inductionPlateChanged },
              model: {
                value: _vm.inductionPlate,
                callback: function($$v) {
                  _vm.inductionPlate = $$v
                },
                expression: "inductionPlate"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#cooker-control-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "cooker-control-filter"
            }
          },
          [_vm._v(" Self-sufficient cooker control ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "cooker-control-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.selfSufficients,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select self sufficiencies",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.selfSufficientChanged },
              model: {
                value: _vm.selfSufficient,
                callback: function($$v) {
                  _vm.selfSufficient = $$v
                },
                expression: "selfSufficient"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#height-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "height-filter"
            }
          },
          [_vm._v(" Height ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "height-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.heights,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select heights",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.heightChanged },
              model: {
                value: _vm.height,
                callback: function($$v) {
                  _vm.height = $$v
                },
                expression: "height"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#depth-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "depth-filter"
            }
          },
          [_vm._v(" Depth ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "depth-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.depths,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select depths",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.depthChanged },
              model: {
                value: _vm.depth,
                callback: function($$v) {
                  _vm.depth = $$v
                },
                expression: "depth"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#installation-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "installation-filter"
            }
          },
          [_vm._v(" Installation Type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "installation-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.installations,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select installation types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.installationChanged },
              model: {
                value: _vm.installation,
                callback: function($$v) {
                  _vm.installation = $$v
                },
                expression: "installation"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#control-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "type-filter"
            }
          },
          [_vm._v(" Type of control ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "control-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.controlTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select control types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.controlTypeChanged },
              model: {
                value: _vm.controlType,
                callback: function($$v) {
                  _vm.controlType = $$v
                },
                expression: "controlType"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#source-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "source-filter"
            }
          },
          [_vm._v(" Energy Sources ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "source-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.energySources,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select energy sources",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.energySourceChanged },
              model: {
                value: _vm.energySource,
                callback: function($$v) {
                  _vm.energySource = $$v
                },
                expression: "energySource"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#plug-type-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "plug-type-filter"
            }
          },
          [_vm._v(" Plug type ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "plug-type-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.plugTypes,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select plug types",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.plugTypeChanged },
              model: {
                value: _vm.plugType,
                callback: function($$v) {
                  _vm.plugType = $$v
                },
                expression: "plugType"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#material-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "material-filter"
            }
          },
          [_vm._v(" Basic surface material ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "material-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.surfaceMaterials,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select basic surface materials",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.surfaceMaterialChanged },
              model: {
                value: _vm.surfaceMaterial,
                callback: function($$v) {
                  _vm.surfaceMaterial = $$v
                },
                expression: "surfaceMaterial"
              }
            })
          ],
          1
        )
      ]),
      _c("div", { staticClass: "filter-options dropdown" }, [
        _c(
          "h6",
          {
            staticClass: "heading",
            attrs: {
              "data-toggle": "collapse",
              "data-target": "#number-of-burner-filter",
              role: "button",
              "aria-expanded": "true",
              "aria-controls": "number-of-burner-filter"
            }
          },
          [_vm._v(" Total number of cooktop burners ")]
        ),
        _c(
          "div",
          {
            staticClass: "wrapper collapse show",
            attrs: { id: "number-of-burner-filter" }
          },
          [
            _c("VueMultiselect", {
              attrs: {
                options: _vm.numberOfBurners,
                multiple: true,
                "close-on-select": true,
                "open-direction": "bottom",
                placeholder: "Select number of burners",
                searchable: false,
                selectLabel: "",
                deselectLabel: ""
              },
              on: { input: _vm.numberOfBurnerChanged },
              model: {
                value: _vm.numberOfBurner,
                callback: function($$v) {
                  _vm.numberOfBurner = $$v
                },
                expression: "numberOfBurner"
              }
            })
          ],
          1
        )
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("li", [_c("span", [_vm._v("to")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }